<template>
  <div class="page-wrapper">
    <b-container fluid>
       <bo-page-title>
      </bo-page-title>
      <b-row>
        <b-col lg="3">
          <div class="card_dashboard">
            <b-row>
              <b-col lg="4">
                <div class="wrap_ic_dsb bg_card_1">
                  <i class="ti-pencil-alt"></i>
                </div>
              </b-col>
              <b-col lg="8">
                <div class="wrap_total_sts">
                  <h3>120</h3>
                  <p>Total Articles</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col lg="3">
          <div class="card_dashboard">
            <b-row>
              <b-col lg="4">
                <div class="wrap_ic_dsb bg_card_2">
                  <i class="ti-file"></i>
                </div>
              </b-col>
              <b-col lg="8">
                <div class="wrap_total_sts">
                  <h3>40</h3>
                  <p>Accommodation</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col lg="3">
          <div class="card_dashboard">
            <b-row>
              <b-col lg="4">
                <div class="wrap_ic_dsb bg_card_3">
                  <i class="ti-file"></i>
                </div>
              </b-col>
              <b-col lg="8">
                <div class="wrap_total_sts">
                  <h3>50</h3>
                  <p>Xperience</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col lg="3">
          <div class="card_dashboard">
            <b-row>
              <b-col lg="4">
                <div class="wrap_ic_dsb bg_card_4">
                  <i class="ti-file"></i>
                </div>
              </b-col>
              <b-col lg="8">
                <div class="wrap_total_sts">
                  <h3>30</h3>
                  <p>Flight</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <b-card no-body class="mt-3">
        <b-card-header>
          <b-row>
            <b-col lg="9">
              <h5 class="card-title">Most Viewed Articles</h5>
            </b-col>
            <b-col lg="3" class="text-right">
              <h5 class="card-title">
                <a href="javascript:;" class="btn btn-success btn-rounded"> View All <i
                    class="fa fa-arrow-circle-right ml-2"></i></a>
              </h5>
            </b-col>
          </b-row>
        </b-card-header>
        <b-card-body class="p-0">
          <table class="table table-hover table-striped table-bordered">
            <thead>
              <tr>
                <th scope="col">
                #
                </th>
                <th scope="col">Title</th>
                <th scope="col">Categories</th>
                <th scope="col">Status</th>
                <th scope="col">Statistic</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>
                  1
                </th>
                <td>
                  <div class="wrap_title_post">
                    <h3><a href="">Tampilan Anyar Candi Borobudur, Bentuk Pariwisata yang Sustainable?</a></h3>
                    <ul>
                      <li><i class="icon-user"></i> Administrator</li>
                      <li><i class=" ti-calendar"></i> 2021/11/08 at 9:00 am</li>
                    </ul>
                  </div>
                </td>
                <td>Accommodation</td>
                <td>
                  <span class="badge badge-success">Published</span><br>
                </td>
                <td>
                  <div class="wrap_title_post">
                    <ul>
                      <li><i class="icon-eye"></i> 152</li>

                      <li><a href=""><i class="icon-bubbles"></i> 22</a></li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <th>2
                </th>
                <td>
                  <div class="wrap_title_post">
                    <h3><a href="">Pariwisata Labuan Bajo Kembali Mengeliat</a></h3>
                    <ul>
                      <li><i class="icon-user"></i>Administrator</li>
                      <li><i class=" ti-calendar"></i> 2021/11/08 at 9:00 am</li>
                    </ul>
                  </div>
                </td>
                <td>Xperience</td>
                <td>
                  <span class="badge badge-success">Published</span><br>
                </td>
                <td>
                  <div class="wrap_title_post">
                    <ul>
                      <li><i class="icon-eye"></i> 152</li>

                      <li><a href=""><i class="icon-bubbles"></i> 22</a></li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  3
                </th>
                <td>
                  <div class="wrap_title_post">
                    <h3><a href="">Good News! Menhub Minta Maskapai Sediakan Tiket Terjangkau bagi Masyarakat</a></h3>
                    <ul>
                      <li><i class="icon-user"></i>Flight</li>
                      <li><i class=" ti-calendar"></i> 2021/11/08 at 9:00 am</li>
                    </ul>
                  </div>
                </td>
                <td>Pinjaman Online</td>
                <td>
                  <span class="badge badge-success">Published</span><br>
                </td>
                <td>
                  <div class="wrap_title_post">
                    <ul>
                      <li><i class="icon-eye"></i> 152</li>

                      <li><a href=""><i class="icon-bubbles"></i> 22</a></li>
                    </ul>
                  </div>
                </td>
              
              </tr>
              <tr>
                <th>
                  4
                </th>
                <td>
                  <div class="wrap_title_post">
                    <h3><a href="">Kemenparekraf Lirik Kota Malang, Kembangkan Pariwisata Era Society 5.0</a></h3>
                    <ul>
                      <li><i class="icon-user"></i>Accommodation</li>
                      <li><i class=" ti-calendar"></i> 2021/11/08 at 9:00 am</li>
                    </ul>
                  </div>
                </td>
                <td>Pinjaman Online</td>
                <td>
                  <span class="badge badge-success">Published</span><br>
                </td>
                <td>
                  <div class="wrap_title_post">
                    <ul>
                      <li><i class="icon-eye"></i> 152</li>

                      <li><a href=""><i class="icon-bubbles"></i> 22</a></li>
                    </ul>
                  </div>
                </td>
              
              </tr>
            </tbody>
          </table>
        </b-card-body>
      </b-card>
    </b-container>
  </div>
</template>
<script>
  import GlobalVue from '../libs/Global.vue'
  import BoPageTitle from '@/components/BoPageTitle.vue'

  export default {
    extends: GlobalVue,
    components: {
      BoPageTitle,
    },
    data() {
      return {
        value2: '',
        users: [{
            no: 1,
            artilce_title: 'Indonesian Beauty Consumers: Summary of Our Talk in Beautypreneur Talk Event',
            views: '90 views'
          },
          {
            no: 2,
            artilce_title: 'How Indonesian Create Meaning Through Foods & Beverages During Pandemic',
            views: '74 views'
          },
          {
            no: 3,
            artilce_title: 'President Director Message',
            views: '60 views'
          },
          {
            no: 4,
            artilce_title: 'Embracing the Un-Normal Series. 1: "Indonesian Sentiments: Helping Your Consumers Regain Control"',
            views: '51 views'
          }
        ]
      }
    }
  }
</script>